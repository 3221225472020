var render = function () {
  var _vm$project, _vm$project2, _vm$project$city$name, _vm$project3, _vm$project3$city, _vm$project4, _vm$project4$meta, _vm$project5, _vm$project5$meta, _vm$project$meta, _vm$project6, _vm$project7, _vm$project7$meta, _vm$project8, _vm$project9;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('add-cost-modal', {
    ref: "addCostModal",
    on: {
      "updated": _vm.fetchData
    }
  }), _c('add-hours-modal', {
    ref: "addHoursModal",
    on: {
      "saved": _vm.fetchData
    }
  }), _c('close-project-modal', {
    ref: "closeProjectModal",
    on: {
      "updated": _vm.fetchData
    }
  }), _c('add-event', {
    ref: "addEventModal",
    on: {
      "updated": _vm.fetchData
    }
  }), _vm.invoiceModal.visible ? _c('ProjectInvoicesModal', {
    attrs: {
      "invoice-data": _vm.invoiceModal,
      "project-id": _vm.project.id
    },
    on: {
      "close": function ($event) {
        _vm.invoiceModal.visible = false;
      },
      "refresh": _vm.fetchData
    }
  }) : _vm._e(), _vm.pageLoading ? _c('page-loader') : [_c('div', {
    staticClass: "row no-print"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6 buttons"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.hoursSaveToday
    }
  }, [_vm._v("Zapsat hodiny")]), _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.addNote
    }
  }, [_vm._v("Přidat fotky")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addOtherCost('shop');
      }
    }
  }, [_vm._v("Přidat z obchodu")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addOtherCost('stock');
      }
    }
  }, [_vm._v("Přidat ze skladu")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addOtherCost('transport');
      }
    }
  }, [_vm._v("Přidat dopravu")]), _vm.showClosingProposal ? [_c('app-button', {
    staticClass: "btn-secondary",
    attrs: {
      "slug": "btn_createProposal"
    },
    on: {
      "click": _vm.createProposal
    }
  }, [_vm._v(" Návrhnout uzavření zakázky ")])] : _vm._e()], 2), _c('div', {
    staticClass: "col-12 col-lg-6 flex-buttons"
  }, [_vm.userCan('offices') ? _c('a', {
    staticClass: "btn btn-primary btn-icon",
    attrs: {
      "href": _vm.project.export_link,
      "slug": "btn_exportProject",
      "download": ""
    }
  }, [_c('icon', {
    attrs: {
      "name": "download"
    }
  }), _vm._v(" Exportovat ")], 1) : _vm._e(), _vm.userCan('project.update') ? _c('router-link', {
    staticClass: "btn btn-danger btn-icon",
    attrs: {
      "to": {
        name: 'project_edit',
        params: {
          project_id: _vm.project.id
        }
      }
    }
  }, [_vm._v(" Upravit zakázku "), _c('icon', {
    attrs: {
      "name": "edit"
    }
  })], 1) : _vm._e()], 1)]), _c('h2', [_vm._v("Zakázka - " + _vm._s((_vm$project = _vm.project) === null || _vm$project === void 0 ? void 0 : _vm$project.name))]), _vm.project.state.slug !== 'closed' && _vm.project.closing_proposal ? _c('div', {
    staticClass: "alert alert-success"
  }, [_vm._v(" Byl podán návrh na uzavření zakázky. ")]) : _vm._e(), _c('div', {
    staticClass: "tabs"
  }, [_c('h3', {
    staticClass: "tab__item",
    class: {
      'active': _vm.tab == 'main'
    },
    on: {
      "click": function ($event) {
        _vm.tab = 'main';
      }
    }
  }, [_vm._v("Detail")]), _c('h3', {
    staticClass: "tab__item",
    class: {
      'active': _vm.tab == 'history'
    },
    on: {
      "click": function ($event) {
        _vm.tab = 'history';
      }
    }
  }, [_vm._v("Historie hodin")])]), _vm.tab == 'main' ? [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-4"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('tr', [_c('td', {
    staticClass: "bold"
  }, [_vm._v("Zákazník:")]), _c('td', [_c('client-link', {
    attrs: {
      "item": (_vm$project2 = _vm.project) === null || _vm$project2 === void 0 ? void 0 : _vm$project2.client
    }
  })], 1)]), _c('tr', [_c('td', {
    staticClass: "bold"
  }, [_vm._v("Město:")]), _c('td', [_vm._v(_vm._s((_vm$project$city$name = (_vm$project3 = _vm.project) === null || _vm$project3 === void 0 ? void 0 : (_vm$project3$city = _vm$project3.city) === null || _vm$project3$city === void 0 ? void 0 : _vm$project3$city.name) !== null && _vm$project$city$name !== void 0 ? _vm$project$city$name : "Nevyplněno"))])]), _vm.project.note ? _c('tr', [_c('td', {
    staticClass: "bold"
  }, [_vm._v("Popis:")]), _c('td', [_vm._v(_vm._s(_vm.project.note))])]) : _vm._e(), _vm.isAdmin() && (_vm$project4 = _vm.project) !== null && _vm$project4 !== void 0 && (_vm$project4$meta = _vm$project4.meta) !== null && _vm$project4$meta !== void 0 && _vm$project4$meta.online_document ? _c('tr', [_c('td', {
    staticClass: "bold"
  }, [_vm._v("Online tabulka")]), _c('td', [_c('a', {
    staticClass: "btn btn-dark",
    attrs: {
      "target": "_blank",
      "href": (_vm$project5 = _vm.project) === null || _vm$project5 === void 0 ? void 0 : (_vm$project5$meta = _vm$project5.meta) === null || _vm$project5$meta === void 0 ? void 0 : _vm$project5$meta.online_document
    }
  }, [_vm._v("Otevřít tabulku")])])]) : _vm._e(), (_vm$project$meta = _vm.project.meta) !== null && _vm$project$meta !== void 0 && _vm$project$meta.address || _vm.project.map_link ? _c('tr', [_c('td', {
    staticClass: "bold"
  }, [_vm._v("Adresa:")]), _c('td', [_vm._v(_vm._s(_vm.project.meta.address) + " "), _vm.project.map_link ? _c('a', {
    staticClass: "btn btn-dark",
    attrs: {
      "target": "_blank",
      "href": _vm.project.map_link
    }
  }, [_vm._v("Otevřít mapu")]) : _vm._e()])]) : _vm._e()])]), _c('div', {
    staticClass: "col-12 col-lg-4"
  }), _c('div', {
    staticClass: "col-12 col-lg-4"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('tr', [_c('td', {
    staticClass: "bold"
  }, [_vm._v("Verifikace:")]), _c('td', [_c('span', {
    class: 'bold text-' + _vm.project.verified_state.classname
  }, [_c('span', [_vm._v(_vm._s(_vm.project.verified_state.label))])])]), _c('td', [_vm.userCan('offices') ? _c('button', {
    staticClass: "btn btn-icon btn-secondary",
    on: {
      "click": _vm.toggleVerify
    }
  }, [_vm._v(" Upravit "), _c('icon', {
    attrs: {
      "name": "edit"
    }
  })], 1) : _vm._e()])]), _c('tr', [_c('td', {
    staticClass: "bold"
  }, [_vm._v("Fáze:")]), _c('td', [_vm._v(" " + _vm._s(_vm.project.state.label) + " "), _vm.project.state.slug == 'closed' ? _c('span', [_vm._v("(" + _vm._s(_vm._f("moment")(_vm.project.finished_at, 'DD. MM. YYYY')) + ")")]) : _vm._e()]), _c('td', [_vm.userCan('offices') ? [_vm.project.state.slug == 'planned' ? [_c('app-button', {
    staticClass: "btn-success",
    attrs: {
      "slug": "btn_setStatus"
    },
    on: {
      "click": function ($event) {
        return _vm.setStatus('public');
      }
    }
  }, [_vm._v(" Zveřejnit zakázku ")])] : _vm.project.state.slug == 'closed' ? [_c('app-button', {
    staticClass: "btn-secondary",
    attrs: {
      "slug": "btn_setStatus"
    },
    on: {
      "click": function ($event) {
        return _vm.setStatus('open');
      }
    }
  }, [_vm._v(" Otevřít zakázku ")])] : [_c('app-button', {
    staticClass: "btn-secondary",
    attrs: {
      "slug": "btn_setStatus"
    },
    on: {
      "click": function ($event) {
        return _vm.setStatus('planned');
      }
    }
  }, [_vm._v(" Do plánovaných ")]), _c('app-button', {
    staticClass: "btn-secondary btn-success",
    attrs: {
      "slug": "btn_setStatus"
    },
    on: {
      "click": function ($event) {
        return _vm.setStatus('close');
      }
    }
  }, [_vm._v(" Uzavřít zakázku ")])]] : _vm._e()], 2)]), _vm.projectStates.invoicing.allowed && _vm.userCan('offices') ? _c('tr', [_c('td', {
    staticClass: "bold"
  }, [_vm._v("Fakturace:")]), _c('td', [_c('span', {
    staticClass: "bold",
    class: _vm.projectStates.invoicing.labelClass
  }, [_vm._v(_vm._s(_vm.projectStates.invoicing.label))]), _vm.project.is_invoiced == '1' && (_vm$project6 = _vm.project) !== null && _vm$project6 !== void 0 && _vm$project6.invoice_numbers ? _c('span', [_vm._v("(" + _vm._s(_vm.project.invoice_number) + ")")]) : _vm._e(), _vm.project.is_invoiced == '2' && (_vm$project7 = _vm.project) !== null && _vm$project7 !== void 0 && (_vm$project7$meta = _vm$project7.meta) !== null && _vm$project7$meta !== void 0 && _vm$project7$meta.invoice_reason ? _c('div', [_vm._v("(" + _vm._s(_vm.project.meta.invoice_reason) + ")")]) : _vm._e()]), _c('td', [_c('button', {
    staticClass: "btn btn-secondary btn-icon",
    on: {
      "click": function ($event) {
        _vm.invoiceModal.visible = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.projectStates.invoicing.button) + " "), _c('icon', {
    attrs: {
      "name": "edit"
    }
  })], 1)])]) : _vm._e()])])]), _c('div', {
    staticClass: "print-sheet"
  }, [_c('div', {
    staticClass: "row align-items-end justify-content-between"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('h3', [_vm._v("Odpracované hodiny")]), _c('div', [_c('b', [_vm._v("Celkem - " + _vm._s(_vm.getTotalHours) + " hod.")])]), _vm._l(_vm.hoursByRoles, function (hours, rolename) {
    return _c('div', [_vm._v(_vm._s(rolename) + " - " + _vm._s(hours) + " hod.")]);
  })], 2), _c('div', {
    staticClass: "col-12 col-lg-3 d-flex flex-column align-items-end"
  }, [_c('button', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        _vm.showFilterFromTo = !_vm.showFilterFromTo;
      }
    }
  }, [_vm._v("Filtrovat od-do")]), _vm.showFilterFromTo ? _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter_from,
      expression: "filter_from"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "date"
    },
    domProps: {
      "value": _vm.filter_from
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.filter_from = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter_to,
      expression: "filter_to"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "date"
    },
    domProps: {
      "value": _vm.filter_to
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.filter_to = $event.target.value;
      }
    }
  }), _c('div', {
    staticClass: "flex-buttons"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        return _vm.filterHours(_vm.filter_from, _vm.filter_to);
      }
    }
  }, [_vm._v("Vyfiltrovat")]), _c('button', {
    staticClass: "btn btn-danger",
    on: {
      "click": function () {
        _vm.filterHours(null, null);

        _vm.showFilterFromTo = false;
      }
    }
  }, [_vm._v("Zrušit")])])]) : _vm._e()])]), _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table table-striped table-hours mt-3"
  }, [_c('tbody', [_c('tr', {
    staticClass: "table__mainrow"
  }, [_c('td', [_vm._v("Uživatel")]), _vm._l(_vm.projectDays, function (day) {
    return _c('td', {
      key: day.key,
      staticClass: "td--day",
      domProps: {
        "innerHTML": _vm._s(day.label)
      }
    });
  }), _c('td', {
    staticClass: "td--total"
  }, [_vm._v("Celkem")])], 2), _vm._l(_vm.projectUsers, function (user) {
    return user.id ? _c('tr', {
      key: user.id
    }, [_c('td', [_c('user-link', {
      attrs: {
        "item": user
      }
    })], 1), _vm._l(_vm.projectDays, function (day) {
      return _c('td', {
        key: day.key,
        staticClass: "td--day"
      }, [_c('a', {
        staticClass: "no-underline",
        on: {
          "click": function ($event) {
            return _vm.updateHours(user.id, day.key);
          }
        }
      }, [_vm._v(_vm._s(_vm.getUserHours(user.id, day.key) || "-"))])]);
    }), _c('td', {
      staticClass: "td--total"
    }, [_vm._v(_vm._s(_vm.getUserTotalHours(user.id)))])], 2) : _vm._e();
  })], 2)])])]), _c('div', {
    staticClass: "print-sheet"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('h3', [_vm._v("Materiál z obchodu")])]), _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', {
    staticClass: "table__mainrow"
  }, [_c('td', [_vm._v("Název")]), _c('td', [_vm._v("Částečný")]), _c('td', [_vm._v("Fotky (" + _vm._s(_vm.costsFromShop.map(function (i) {
    var _i$files;

    return ((_i$files = i.files) !== null && _i$files !== void 0 ? _i$files : []).length;
  }).sum()) + ")")]), _c('td', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Hodnota")]), _c('td', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Číslo dokladu")]), _c('td', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Zapsal")])]), _vm._l(_vm.costsFromShop, function (item) {
    var _item$parent$meta, _item$meta, _item$user$name, _item$user;

    return _c('tr', {
      key: item.id
    }, [_c('td', [_c('cost-link', {
      attrs: {
        "item": item
      }
    })], 1), _c('td', [_vm._v(" " + _vm._s(item.parent_id ? "Ano" : "Ne") + " ")]), _c('td', [_c('images-info', {
      attrs: {
        "item": item
      }
    })], 1), _c('td', [_vm._v(" " + _vm._s(item.value) + " ")]), _c('td', [_vm._v(" " + _vm._s(item !== null && item !== void 0 && item.parent ? (_item$parent$meta = item.parent.meta) === null || _item$parent$meta === void 0 ? void 0 : _item$parent$meta.document_number : (_item$meta = item.meta) === null || _item$meta === void 0 ? void 0 : _item$meta.document_number) + " ")]), _c('td', [_vm._v(" " + _vm._s((_item$user$name = item === null || item === void 0 ? void 0 : (_item$user = item.user) === null || _item$user === void 0 ? void 0 : _item$user.name) !== null && _item$user$name !== void 0 ? _item$user$name : "Anonym") + ", " + _vm._s(_vm._f("moment")(item.created_at, "DD. MM. YYYY HH:mm")) + " ")])]);
  })], 2)])])]), _c('div', {
    staticClass: "print-sheet"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('h3', [_vm._v("Materiál ze skladu")])]), _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', {
    staticClass: "table__mainrow"
  }, [_c('td', [_vm._v("Název")]), _c('td', [_vm._v("Částečný")]), _c('td', [_vm._v("Fotky (" + _vm._s(_vm.costsFromStock.map(function (i) {
    var _i$files2;

    return ((_i$files2 = i.files) !== null && _i$files2 !== void 0 ? _i$files2 : []).length;
  }).sum()) + ")")]), _c('td', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Zapsal")])]), _vm._l(_vm.costsFromStock, function (item) {
    var _item$user$name2, _item$user2;

    return _c('tr', {
      key: item.id
    }, [_c('td', [_c('cost-link', {
      attrs: {
        "item": item
      }
    })], 1), _c('td', [_vm._v(" " + _vm._s(item.parent_id ? "Ano" : "Ne") + " ")]), _c('td', [_c('images-info', {
      attrs: {
        "item": item
      }
    })], 1), _c('td', [_vm._v(" " + _vm._s((_item$user$name2 = item === null || item === void 0 ? void 0 : (_item$user2 = item.user) === null || _item$user2 === void 0 ? void 0 : _item$user2.name) !== null && _item$user$name2 !== void 0 ? _item$user$name2 : "Anonym") + ", " + _vm._s(_vm._f("moment")(item.created_at, "DD. MM. YYYY HH:mm")) + " ")])]);
  })], 2)])])]), _c('div', {
    staticClass: "print-sheet"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('h3', [_vm._v("Vozidla")])]), _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', {
    staticClass: "table__mainrow"
  }, [_c('td', [_vm._v("Vozidlo")]), _c('td', [_vm._v("Fotky (" + _vm._s(_vm.costsFromTransport.map(function (i) {
    var _i$files3;

    return ((_i$files3 = i.files) !== null && _i$files3 !== void 0 ? _i$files3 : []).length;
  }).sum()) + ")")]), _c('td', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Kolikrát na stavbě (" + _vm._s(_vm.costsFromTransport.map(function (i) {
    return parseInt(i.meta.count || 0);
  }).sum()) + ")")]), _c('td', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Zapsal")])]), _vm._l(_vm.costsFromTransport, function (item) {
    var _item$user$name3, _item$user3;

    return _c('tr', {
      key: item.id
    }, [_c('td', [_c('cost-link', {
      attrs: {
        "item": item
      }
    })], 1), _c('td', [_c('images-info', {
      attrs: {
        "item": item
      }
    })], 1), _c('td', [_vm._v(_vm._s(item.meta.count))]), _c('td', [_vm._v(_vm._s((_item$user$name3 = item === null || item === void 0 ? void 0 : (_item$user3 = item.user) === null || _item$user3 === void 0 ? void 0 : _item$user3.name) !== null && _item$user$name3 !== void 0 ? _item$user$name3 : "Anonym") + ", " + _vm._s(_vm._f("moment")(item.created_at, "DD. MM. YYYY HH:mm")))])]);
  })], 2)])])]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('h3', [_vm._v("Dokumenty")])]), _c('project-files', {
    attrs: {
      "files": _vm.project.documents,
      "model-type": "project",
      "model-id": (_vm$project8 = _vm.project) === null || _vm$project8 === void 0 ? void 0 : _vm$project8.id,
      "allow-upload": _vm.isAdmin(),
      "column": "documents"
    },
    on: {
      "updated": _vm.fetchData
    }
  }), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('h3', [_vm._v("Fotky")])]), _c('project-files', {
    attrs: {
      "files": _vm.project.files,
      "model-type": "project",
      "model-id": (_vm$project9 = _vm.project) === null || _vm$project9 === void 0 ? void 0 : _vm$project9.id,
      "allow-upload": true
    },
    on: {
      "updated": _vm.fetchData
    }
  })] : _vm._e(), _vm.tab == 'history' ? [_c('div', {
    staticClass: "print-sheet"
  }, [_c('div', {
    staticClass: "cards cards--hours"
  }, _vm._l(_vm.projectHoursByDays, function (day) {
    var _day$items$0$meta;

    return _c('div', {
      staticClass: "crd"
    }, [_c('div', {
      staticClass: "crd__title"
    }, [_vm._v(_vm._s(_vm.$moment(day.day).format("DD. MM. YYYY")))]), _c('div', {
      staticClass: "crd__subtitle"
    }, [_c('weather-widget', {
      staticClass: "weather--small",
      attrs: {
        "item": day === null || day === void 0 ? void 0 : (_day$items$0$meta = day.items[0].meta) === null || _day$items$0$meta === void 0 ? void 0 : _day$items$0$meta.weather
      }
    })], 1), _vm._l(day.items, function (item) {
      var _item$user4;

      return _c('div', {
        staticClass: "crd__body"
      }, [_c('div', {
        staticClass: "crd__name"
      }, [item !== null && item !== void 0 && (_item$user4 = item.user) !== null && _item$user4 !== void 0 && _item$user4.name ? _c('user-link', {
        attrs: {
          "item": item === null || item === void 0 ? void 0 : item.user,
          "position": false
        }
      }) : _c('span', [_vm._v("Anonoym")]), item.amount ? _c('span', [_vm._v("(" + _vm._s(item.amount) + "h)")]) : _vm._e()], 1), item.note ? _c('div', {
        staticClass: "crd__text"
      }, [_vm._v(_vm._s(item.note))]) : _vm._e(), _c('project-files', {
        staticClass: "gallery--small",
        attrs: {
          "files": item.files,
          "model-type": item.type,
          "model-id": item.id
        },
        on: {
          "imageDeleted": _vm.fetchData
        }
      })], 1);
    })], 2);
  }), 0)])] : _vm._e()]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
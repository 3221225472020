var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container container--layout"
  }, [_c('h2', [_vm._v("Úprava auta")]), _c('form', {
    attrs: {
      "method": "POST",
      "autocomplete": "off"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-4"
  }, [_c('h3', [_vm._v("Základní informace")]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Název")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.car.name,
      expression: "car.name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.car.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.car, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("SPZ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.car.meta.plate,
      expression: "car.meta.plate"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.car.meta.plate
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.car.meta, "plate", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Popis")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.car.meta.info,
      expression: "car.meta.info"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.car.meta.info
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.car.meta, "info", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("VIN kód")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.car.meta.vin_number,
      expression: "car.meta.vin_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "input"
    },
    domProps: {
      "value": _vm.car.meta.vin_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.car.meta, "vin_number", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-12 col-lg-4"
  }, [_c('h3', [_vm._v("Ostatní informace")]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Expirace STK")]), _c('app-date', {
    attrs: {
      "clearable": true
    },
    model: {
      value: _vm.car.meta.stk_expires,
      callback: function ($$v) {
        _vm.$set(_vm.car.meta, "stk_expires", $$v);
      },
      expression: "car.meta.stk_expires"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Údržba auta provedena")]), _c('app-date', {
    attrs: {
      "clearable": true
    },
    model: {
      value: _vm.car.meta.car_maintenance_at,
      callback: function ($$v) {
        _vm.$set(_vm.car.meta, "car_maintenance_at", $$v);
      },
      expression: "car.meta.car_maintenance_at"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Dálniční známka do")]), _c('app-date', {
    attrs: {
      "clearable": true
    },
    model: {
      value: _vm.car.meta.toll_license_expires,
      callback: function ($$v) {
        _vm.$set(_vm.car.meta, "toll_license_expires", $$v);
      },
      expression: "car.meta.toll_license_expires"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Zakázat tankování")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.car.meta.disable_refueling,
      expression: "car.meta.disable_refueling"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.car.meta.disable_refueling) ? _vm._i(_vm.car.meta.disable_refueling, null) > -1 : _vm.car.meta.disable_refueling
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.car.meta.disable_refueling,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.car.meta, "disable_refueling", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.car.meta, "disable_refueling", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.car.meta, "disable_refueling", $$c);
        }
      }
    }
  })])]), _c('div', {
    staticClass: "col-12 col-lg-4"
  }, [_vm.car.id ? _c('manage-cars', {
    attrs: {
      "by-car": _vm.car.id
    }
  }) : _vm._e()], 1)]), _c('app-button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "submit",
      "slug": "btn_saveCar"
    }
  }, [_vm._v("Uložit")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('add-hours-modal', {
    ref: "hoursModal"
  }), _c('h2', [_vm._v("Spojování zakázek")]), _c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-12 col-lg-5"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "text-pid1"
  }, [_vm._v("Hlavní zakázka")]), _c('app-select', {
    attrs: {
      "auto-load": "projects"
    },
    model: {
      value: _vm.pid1,
      callback: function ($$v) {
        _vm.pid1 = $$v;
      },
      expression: "pid1"
    }
  })], 1)]), _c('div', {
    staticClass: "col-12 col-lg-5"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "text-pid2"
  }, [_vm._v("Duplicitní zakázka")]), _c('app-select', {
    attrs: {
      "auto-load": "projects"
    },
    model: {
      value: _vm.pid2,
      callback: function ($$v) {
        _vm.pid2 = $$v;
      },
      expression: "pid2"
    }
  })], 1)]), _c('div', {
    staticClass: "col-12 col-lg-2"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.startMerge
    }
  }, [_vm._v("Načíst data")])])]), _vm.showColumns ? _c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.doMerge.apply(null, arguments);
      }
    }
  }, [_c('h3', [_vm._v("Sloučení hodin")]), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "project1"
    },
    domProps: {
      "value": _vm.pid1
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "project2"
    },
    domProps: {
      "value": _vm.pid2
    }
  }), _c('table', {
    staticClass: "table table-striped table-hours mt-3"
  }, [_c('tbody', [_c('tr', {
    staticClass: "table__mainrow"
  }, [_c('td', [_vm._v("Uživatel")]), _vm._l(_vm.projectDays, function (day) {
    return _c('td', {
      key: day.key,
      staticClass: "td--day",
      domProps: {
        "innerHTML": _vm._s(day.label)
      }
    });
  })], 2), _vm._l(_vm.projectHours, function (item) {
    return _c('tr', {
      key: item.user.id
    }, [_c('td', [_c('user-link', {
      attrs: {
        "item": item.user
      }
    })], 1), _vm._l(item.days, function (day) {
      return _c('td', {
        key: day.key,
        staticClass: "td--day"
      }, [day.total ? _c('div', [_c('div', {
        staticClass: "merge__items"
      }, _vm._l(day.items, function (day_item) {
        return _c('span', {
          class: _vm.getProjectCls(day_item.pid),
          on: {
            "click": function ($event) {
              return _vm.openHours(day_item.id);
            }
          }
        }, [_vm._v(_vm._s(day_item.amount))]);
      }), 0), _c('input', {
        attrs: {
          "type": "text",
          "name": 'hours[' + item.user.id + '][' + day.key + ']'
        },
        domProps: {
          "value": day.total
        }
      })]) : _c('div', [_vm._v(" -")])]);
    })], 2);
  })], 2)]), _c('h3', [_vm._v("Sloučení nákladů")]), _vm._m(0), _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_vm._m(1), _vm._l(_vm.costsFromShop, function (item) {
    var _item$meta, _item$user$name, _item$user;

    return _c('tr', {
      key: item.id
    }, [_c('td', [_c('input', {
      attrs: {
        "type": "checkbox",
        "name": 'costs[]',
        "checked": ""
      },
      domProps: {
        "value": item.id
      }
    })]), _c('td', [_c('div', {
      class: _vm.getProjectCls(item.project_id)
    }, [_c('cost-link', {
      attrs: {
        "item": item
      }
    })], 1)]), _c('td', [_c('images-info', {
      attrs: {
        "item": item
      }
    })], 1), _c('td', [_vm._v(" " + _vm._s(item.parent_id ? "Ano" : "Ne") + " ")]), _c('td', [_vm._v(" " + _vm._s(item.value) + " ")]), _c('td', [_vm._v(" " + _vm._s((_item$meta = item.meta) === null || _item$meta === void 0 ? void 0 : _item$meta.note) + " ")]), _c('td', [_vm._v(" " + _vm._s((_item$user$name = item === null || item === void 0 ? void 0 : (_item$user = item.user) === null || _item$user === void 0 ? void 0 : _item$user.name) !== null && _item$user$name !== void 0 ? _item$user$name : "Anonym") + ", " + _vm._s(_vm._f("moment")(item.created_at, "DD. MM. YYYY HH:mm")) + " ")])]);
  })], 2)])]), _vm._m(2), _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_vm._m(3), _vm._l(_vm.costsFromStock, function (item) {
    var _item$user$name2, _item$user2;

    return _c('tr', {
      key: item.id
    }, [_c('td', [_c('input', {
      attrs: {
        "type": "checkbox",
        "name": 'costs[]',
        "checked": ""
      },
      domProps: {
        "value": item.id
      }
    })]), _c('td', [_c('div', {
      class: _vm.getProjectCls(item.project_id)
    }, [_c('cost-link', {
      attrs: {
        "item": item
      }
    })], 1)]), _c('td', [_c('images-info', {
      attrs: {
        "item": item
      }
    })], 1), _c('td', [_vm._v(" " + _vm._s(item.parent_id ? "Ano" : "Ne") + " ")]), _c('td', [_vm._v(" " + _vm._s((_item$user$name2 = item === null || item === void 0 ? void 0 : (_item$user2 = item.user) === null || _item$user2 === void 0 ? void 0 : _item$user2.name) !== null && _item$user$name2 !== void 0 ? _item$user$name2 : "Anonym") + ", " + _vm._s(_vm._f("moment")(item.created_at, "DD. MM. YYYY HH:mm")) + " ")])]);
  })], 2)])]), _vm._m(4), _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_vm._m(5), _vm._l(_vm.costsFromTransport, function (item) {
    var _item$user$name3, _item$user3;

    return _c('tr', {
      key: item.id
    }, [_c('td', [_c('input', {
      attrs: {
        "type": "checkbox",
        "name": 'costs[]',
        "checked": ""
      },
      domProps: {
        "value": item.id
      }
    })]), _c('td', [_c('div', {
      class: _vm.getProjectCls(item.project_id)
    }, [_c('cost-link', {
      attrs: {
        "item": item
      }
    })], 1)]), _c('td', [_c('images-info', {
      attrs: {
        "item": item
      }
    })], 1), _c('td', [_vm._v(_vm._s(item.meta.count))]), _c('td', [_vm._v(_vm._s((_item$user$name3 = item === null || item === void 0 ? void 0 : (_item$user3 = item.user) === null || _item$user3 === void 0 ? void 0 : _item$user3.name) !== null && _item$user$name3 !== void 0 ? _item$user$name3 : "Anonym") + ", " + _vm._s(_vm._f("moment")(item.created_at, "DD. MM. YYYY HH:mm")))])]);
  })], 2)])]), _c('app-button', {
    staticClass: "btn-primary",
    attrs: {
      "slug": "btn_merge"
    }
  }, [_vm._v("Sloučit")])], 1) : _vm._e()], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('h4', [_vm._v("Materiál z obchodu")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    staticClass: "table__mainrow"
  }, [_c('td', {
    attrs: {
      "width": "70"
    }
  }, [_vm._v("Zahrnout")]), _c('td', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Název")]), _c('td', {
    attrs: {
      "width": "70"
    }
  }, [_vm._v("Fotky")]), _c('td', {
    attrs: {
      "width": "70"
    }
  }, [_vm._v("Částečný")]), _c('td', [_vm._v("Hodnota")]), _c('td', [_vm._v("Číslo dokladu / poznámka")]), _c('td', {
    attrs: {
      "width": "100"
    }
  }, [_vm._v("Zapsal")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('h4', [_vm._v("Materiál ze skladu")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    staticClass: "table__mainrow"
  }, [_c('td', {
    attrs: {
      "width": "70"
    }
  }, [_vm._v("Zahrnout")]), _c('td', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Název")]), _c('td', {
    attrs: {
      "width": "70"
    }
  }, [_vm._v("Fotky")]), _c('td', {
    attrs: {
      "width": "70"
    }
  }, [_vm._v("Částečný")]), _c('td', [_vm._v("Zapsal")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('h4', [_vm._v("Vozidla")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    staticClass: "table__mainrow"
  }, [_c('td', {
    attrs: {
      "width": "70"
    }
  }, [_vm._v("Zahrnout")]), _c('td', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Název")]), _c('td', {
    attrs: {
      "width": "70"
    }
  }, [_vm._v("Fotky")]), _c('td', [_vm._v("Kolikrát na stavbě")]), _c('td', [_vm._v("Zapsal")])]);
}]

export { render, staticRenderFns }
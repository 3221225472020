var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h3', [_vm._v("Neschválené náklady")]), _c('div', {
    staticStyle: {
      "height": "400px",
      "overflow-y": "auto"
    }
  }, [_c('div', {
    staticClass: "table-wrapper"
  }, [_vm.items.length ? _c('table', {
    staticClass: "table table-striped"
  }, [_vm._m(0), _vm._l(_vm.items, function (item) {
    var _item$user$name, _item$user;

    return _c('tr', [_c('td', [_c('cost-link', {
      attrs: {
        "item": item
      }
    })], 1), _c('td', [_vm._v(_vm._s((_item$user$name = item === null || item === void 0 ? void 0 : (_item$user = item.user) === null || _item$user === void 0 ? void 0 : _item$user.name) !== null && _item$user$name !== void 0 ? _item$user$name : "Anonym"))]), _c('td', [_vm._v(_vm._s(_vm._f("moment")(item.created_at, 'DD. MM. YYYY')))])]);
  })], 2) : _c('div', {
    staticClass: "hp__check"
  }, [_c('Icon', {
    attrs: {
      "name": "check"
    }
  })], 1)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('th', [_vm._v("Název")]), _c('th', [_vm._v("Založil")]), _c('th', [_vm._v("Vytvořeno")])]);
}]

export { render, staticRenderFns }
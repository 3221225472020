import { render, staticRenderFns } from "./city_merge.vue?vue&type=template&id=82bd9fd6&"
import script from "./city_merge.vue?vue&type=script&lang=js&"
export * from "./city_merge.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
/* import styles */
import 'vue2-datepicker/index.css';
import './sass/app.scss'
import "vue-toast-notification/dist/theme-default.css"
import './common'

import Vue from 'vue';
import VueRouter from "vue-router";
import Vuex from "vuex";

import layout from "./layout.vue";
import routes from "./routes";
import store from "./store";
import modal from "@components/modal";
import pageLoader from "@components/pageLoader";
import appButton from "@components/appButton";
import multiselect, {Multiselect} from "vue-multiselect";
import appSelect from "@components/appSelect";
import toast from "vue-toast-notification";
import projectLink from "@components/projectLink";
import userLink from "@components/userLink";
import costLink from "@components/costLink";
import clientLink from "@components/clientLink";
import datepicker from "vue2-datepicker";
import "vue2-datepicker/locale/cs";
import appDate from "@components/appDate";
import mixin from "@app/auth/mixin";
import appGrid from "@components/appGrid";
import appTable from "@components/appTable"
import appPagination from "@components/appPagination"
import VueEvents from 'vue-events'
import commonMixin from "@app/features/commonMixin";
import holidayCell from "@components/holidayCell";
import appMultiselect from "@components/appMultiselect";
import projectFiles from "@components/projectFiles";
import icon from "@components/icon";
import taskLink from "@components/taskLink";
import draggable from "vuedraggable";

import Fancybox from "./helpers/fancybox";
import AgGrid from "@components/agGrid";
import Icon from "@components/icon.vue";
import ApolloClient, {HttpLink, InMemoryCache} from 'apollo-boost'
import VueApollo from 'vue-apollo'
import gqlMixin from "@/mixins/gqlMixin";

const httpLink = new HttpLink({
    uri: process.env.VUE_APP_GRAPHQL_ENDPOINT,
});

const apolloClient = new ApolloClient({
    // You should use an absolute URL here
    uri: process.env.VUE_APP_GRAPHQL_ENDPOINT,
    // link: httpLink,
})

window.Fancybox = Fancybox;

Vue.use(VueEvents)
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(toast);
Vue.mixin(gqlMixin)

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
})
Vue.use(VueApollo);

let moment = require('moment');
require('moment/locale/cs');

Vue.use(require('vue-moment'), {
    moment
});

Vue.component("modal", modal);
Vue.component("page-loader", pageLoader);
Vue.component("app-button", appButton);
Vue.component("multiselect", multiselect);
Vue.component("app-multiselect", appMultiselect);
Vue.component("app-select", appSelect);
Vue.component("project-link", projectLink);
Vue.component("project-files", projectFiles);
Vue.component("user-link", userLink);
Vue.component("cost-link", costLink);
Vue.component("client-link", clientLink);
Vue.component("task-link", taskLink);
Vue.component("datepicker", datepicker);
Vue.component("app-date", appDate);
Vue.component("app-grid", appGrid);
Vue.component("app-table", appTable);
Vue.component("app-pagination", appPagination);
Vue.component("holiday-cell", holidayCell);
Vue.component("icon", icon);
Vue.component("draggable", draggable);
Vue.component("ag-grid", AgGrid);
Vue.component("vue-multiselect", Multiselect);
Vue.component("Icon", Icon);

window.ROLE_ADMIN = 1;
window.ROLE_OFFICE = 7;
window.ROLES_OFFICE = [1, 7];
window.ROLE_MAIN_MISTER = 2;


let router = new VueRouter({
    routes,
    mode: 'history',
});

let vuexStore = new Vuex.Store(store);
Vue.config.devtools = true;

Vue.mixin(mixin);
Vue.mixin(commonMixin);

new Vue({
    el: '#app',
    router,
    store: vuexStore,
    render: (h) => h(layout),
    created() {
        this.$moment.locale("cs");
    },
    apolloProvider,
});
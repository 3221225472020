<script>
import {gql} from "graphql-tag";

const getItems = gql`
query {
  projects: workInProgressProjects {
    id
    name
    category
    is_public
    city_id
    city {
        id
        name
        city_id
    }
    client {
        id
        name
    }
    is_verified
  }
}
`

export default {
    name: "DashboardWipProjects",
    data() {
        return {
            filterProjectCategory: null,
            openedCities: [],
            items: [],
        }
    },
    computed: {
        projectsByCities() {
            let cities = {};
            this.wipProjects.forEach(item => {
                let city_id = item.city_id ?? "none";

                if (!cities?.[city_id]) {
                    let city = item?.city ?? {name: "Nezařazené", id: "none"};
                    city.projects = [];
                    cities[city_id] = city;
                }

                delete item.city;
                cities[city_id].projects.push(item);
            });

            cities = Object.values(cities).orderBy("name");
            return cities;
        },
        wipProjects() {
            let items = this.removeReactivity(this.items);
            if (this.filterProjectCategory) {
                items = items.filter(item => item.category == this.filterProjectCategory);
            }
            return items.filter(i => i.is_public);
        },
    },
    methods: {
        fetchData() {
            this.apolloQuery({
                query: getItems
            }).then(res => {
                this.items = res.data.projects;
            })
        }
    },
    mounted() {
        this.fetchData()
    }
}
</script>

<template>
    <div>
        <h3>Rozpracované zakázky</h3>
        <template v-if="userCan('office')">
            <button class="btn btn-secondary" :class="{'btn-primary': filterProjectCategory == null}" @click="filterProjectCategory = null">Vše</button>
            <button class="btn btn-secondary" :class="{'btn-primary': filterProjectCategory == 8}" @click="filterProjectCategory = 8">Projekce</button>
        </template>

        <div v-for="city in projectsByCities">
            <h5 class="table-open" @click="openedCities.toggle(city.id)">
                <button class="btn btn-primary">{{ openedCities.includes(city.id) ? "-" : "+" }}</button>
                {{ city.name }}
            </h5>
            <div class="table-wrapper" v-show="openedCities.includes(city.id)">
                <table class="table table-striped" v-if="city.projects.length">
                    <tr>
                        <th width="30%">Název</th>
                        <th width="30%">Zákazník</th>
                    </tr>
                    <tr v-for="item in city.projects">
                        <td>
                            <project-link :item="item" :hide-client="true"/>
                        </td>
                        <td>
                            <client-link :item="item?.client"/>
                        </td>
                    </tr>
                </table>
                <div v-else class="hp__check">
                    <Icon name="check"/>
                </div>
            </div>
        </div>
    </div>
</template>
var render = function () {
  var _vm$cost$user$name, _vm$cost, _vm$cost$user, _vm$cost$type_name, _vm$cost2, _vm$cost2$files, _vm$cost3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('add-cost-modal', {
    ref: "costModal",
    on: {
      "updated": _vm.fetchData
    }
  }), _vm.pageLoading ? _c('page-loader') : _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('h2', [_vm._v(_vm._s(_vm.cost.title) + " - "), _vm.cost.deleted_at ? _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("SMAZÁNO")]) : _vm.cost.approved ? _c('span', {
    staticClass: "text-success"
  }, [_vm._v("Schváleno")]) : _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("Neschváleno")])])]), !_vm.cost.deleted_at ? _c('div', {
    staticClass: "col-12 col-lg-6 flex-buttons"
  }, [_c('button', {
    staticClass: "btn btn-danger",
    on: {
      "click": _vm.editCost
    }
  }, [_vm._v("Upravit náklad")]), _vm.cost.approved ? _c('app-button', {
    staticClass: "btn-danger",
    attrs: {
      "slug": "btn_approval"
    },
    on: {
      "click": function ($event) {
        return _vm.setApproval(0);
      }
    }
  }, [_vm._v("Neschválit")]) : _c('app-button', {
    staticClass: "btn-success",
    attrs: {
      "slug": "btn_approval"
    },
    on: {
      "click": function ($event) {
        return _vm.setApproval(1);
      }
    }
  }, [_vm._v("Schválit")]), _vm.isAdmin() ? _c('app-button', {
    staticClass: "btn-danger",
    attrs: {
      "slug": "btn_deleteCost"
    },
    on: {
      "click": _vm.deleteCost
    }
  }, [_vm._v("Smazat náklad")]) : _vm._e()], 1) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('h3', [_vm._v("Informace")]), _c('div', {
    staticClass: "task__row"
  }, [_c('div', {
    staticClass: "task__label"
  }, [_vm._v("Vytvořil:")]), _vm._v(" " + _vm._s((_vm$cost$user$name = (_vm$cost = _vm.cost) === null || _vm$cost === void 0 ? void 0 : (_vm$cost$user = _vm$cost.user) === null || _vm$cost$user === void 0 ? void 0 : _vm$cost$user.name) !== null && _vm$cost$user$name !== void 0 ? _vm$cost$user$name : "Anonym") + " v " + _vm._s(_vm._f("moment")(_vm.cost.created_at, "DD. MM. YYYY v HH:mm")) + " ")]), _c('div', {
    staticClass: "task__row"
  }, [_c('div', {
    staticClass: "task__label"
  }, [_vm._v("Typ nákladu:")]), _vm._v(" " + _vm._s((_vm$cost$type_name = _vm.cost.type_name) !== null && _vm$cost$type_name !== void 0 ? _vm$cost$type_name : "Nezadáno") + " ")]), _c('div', {
    staticClass: "task__row"
  }, [_c('div', {
    staticClass: "task__label"
  }, [_vm._v("Hodnota:")]), _vm._v(" " + _vm._s(_vm.cost.value ? _vm.cost.value + " Kč" : "Nezadáno") + " ")]), _c('h3', [_vm._v("Meta informace")]), _vm._l(_vm.metas, function (meta) {
    return _c('div', {
      staticClass: "task__row"
    }, [_c('div', {
      staticClass: "task__label"
    }, [_vm._v(_vm._s(meta.key) + ":")]), _vm._v(" " + _vm._s(meta.value) + " ")]);
  }), (_vm$cost2 = _vm.cost) !== null && _vm$cost2 !== void 0 && (_vm$cost2$files = _vm$cost2.files) !== null && _vm$cost2$files !== void 0 && _vm$cost2$files.length ? _c('div', [_c('h3', [_vm._v("Soubory")]), _c('project-files', {
    attrs: {
      "files": _vm.cost.files,
      "model-type": "cost",
      "model-id": (_vm$cost3 = _vm.cost) === null || _vm$cost3 === void 0 ? void 0 : _vm$cost3.id
    }
  })], 1) : _vm._e()], 2), _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('h3', [_vm._v("Zakázky")])]), !_vm.cost_project && !_vm.cost_projects.length && !_vm.cost.deleted_at ? _c('div', {
    staticClass: "col-6 flex-buttons"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        return _vm.addToProjects(false);
      }
    }
  }, [_vm._v("Přidat k jediné zakázce")]), _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        return _vm.addToProjects(true);
      }
    }
  }, [_vm._v("Přidat k více zakázkám")])]) : _vm._e()]), _vm.cost_projects.length ? _c('div', [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Zakázka")]), _c('th', [_vm._v("Název položky")]), _c('th', [_vm._v("Částka")]), _c('th')])]), _c('tbody', _vm._l(_vm.cost_projects, function (item) {
    return _c('tr', [_c('td', [_c('project-link', {
      attrs: {
        "item": item.project
      }
    })], 1), _c('td', [_vm._v(_vm._s(item.title))]), _c('td', [_vm._v(_vm._s(item.value))]), _c('td', [_c('button', {
      staticClass: "btn btn-danger",
      on: {
        "click": function ($event) {
          return _vm.editSubCost(item);
        }
      }
    }, [_vm._v("Upravit")]), _c('app-button', {
      staticClass: "btn btn-danger",
      attrs: {
        "slug": 'btn_unassignProjectToCost_' + item.id
      },
      on: {
        "click": function ($event) {
          return _vm.deleteSubcost(item.id);
        }
      }
    }, [_vm._v("Odebrat")])], 1)]);
  }), 0)]), _c('div', {
    staticClass: "flex-buttons"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        return _vm.addToProjects(true);
      }
    }
  }, [_vm._v("Přidat další zakázku")])])]) : _vm.cost.project ? _c('div', [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Hlavní zakázka")]), _c('th')])]), _c('tbody', [_c('tr', [_c('td', [_c('project-link', {
    attrs: {
      "item": _vm.cost.project
    }
  })], 1), _c('td', [_c('app-button', {
    staticClass: "btn btn-danger",
    attrs: {
      "slug": 'btn_unassignProjectToCost'
    },
    on: {
      "click": function ($event) {
        return _vm.unassignProject();
      }
    }
  }, [_vm._v("Odebrat")])], 1)])])])]) : _vm._e(), _vm.assignProject.show ? _c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.assignProjectToCost.apply(null, arguments);
      }
    }
  }, [_c('input', {
    attrs: {
      "type": "hidden",
      "name": "cost_id"
    },
    domProps: {
      "value": _vm.cost_id
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "subcost_id"
    },
    domProps: {
      "value": _vm.assignProject.subcost_id
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "multiple"
    },
    domProps: {
      "value": _vm.assignProject.multiple ? 1 : 0
    }
  }), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Zakázka")]), _c('app-select', {
    attrs: {
      "auto-load": "projects",
      "name": "project_id",
      "placeholder": "Vyberte zakázku",
      "required": ""
    },
    model: {
      value: _vm.assignProject.project_id,
      callback: function ($$v) {
        _vm.$set(_vm.assignProject, "project_id", $$v);
      },
      expression: "assignProject.project_id"
    }
  })], 1), _vm.assignProject.multiple ? [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Název položky")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.assignProject.cost_name,
      expression: "assignProject.cost_name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "cost_name",
      "required": ""
    },
    domProps: {
      "value": _vm.assignProject.cost_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.assignProject, "cost_name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Hodnota")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.assignProject.cost_value,
      expression: "assignProject.cost_value"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "cost_value"
    },
    domProps: {
      "value": _vm.assignProject.cost_value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.assignProject, "cost_value", $event.target.value);
      }
    }
  })])] : _vm._e(), _c('div', {
    staticClass: "flex-buttons"
  }, [_c('app-button', {
    staticClass: "btn-primary",
    attrs: {
      "slug": "btn_assignProjectToCost"
    }
  }, [_vm._v(" Uložit ")]), _c('button', {
    staticClass: "btn btn-danger",
    on: {
      "click": function ($event) {
        _vm.assignProject.show = false;
      }
    }
  }, [_vm._v("Zrušit")])], 1)], 2) : _vm._e()])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm$item, _vm$item$user, _vm$item2, _vm$item3, _vm$item4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    staticClass: "popup"
  }, [_c('div', {
    staticClass: "popup__wrapper"
  }, [_c('div', {
    staticClass: "popup__background",
    on: {
      "click": _vm.close
    }
  }), _vm.viewLoading ? _c('div', [_vm._v("Načítání ...")]) : _vm._e(), _vm.viewMode ? _c('div', {
    staticClass: "popup__body"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-6 flex-buttons"
  }, [_vm.canEditHours ? _c('button', {
    staticClass: "btn btn-danger",
    on: {
      "click": function ($event) {
        _vm.viewMode = false;
      }
    }
  }, [_vm._v("Upravit")]) : _vm._e()])]), _c('b', [_vm._v("Kdo:")]), _vm._v(" " + _vm._s((_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : (_vm$item$user = _vm$item.user) === null || _vm$item$user === void 0 ? void 0 : _vm$item$user.name)), _c('br'), _vm._v(" "), _c('b', [_vm._v("Kdy:")]), _vm._v(" " + _vm._s(_vm._f("moment")((_vm$item2 = _vm.item) === null || _vm$item2 === void 0 ? void 0 : _vm$item2.day, "DD. MM. YYYY"))), _c('br'), _c('b', [_vm._v("Kolik:")]), _vm._v(" " + _vm._s((_vm$item3 = _vm.item) === null || _vm$item3 === void 0 ? void 0 : _vm$item3.amount) + " hodin"), _c('br'), _vm._v(" "), _c('b', [_vm._v("Poznámka:")]), _vm._v(" " + _vm._s(((_vm$item4 = _vm.item) === null || _vm$item4 === void 0 ? void 0 : _vm$item4.note) || "- nezadána -")), _c('br'), _vm._v(" "), _c('br'), _c('project-files', {
    attrs: {
      "files": _vm.item.files,
      "model-type": "project_hour",
      "model-id": _vm.item.id
    }
  })], 1) : _c('form', {
    staticClass: "popup__body",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.save.apply(null, arguments);
      }
    }
  }, [_vm.allLoaded ? [_vm.select_project ? [_c('div', {
    staticClass: "popup__title"
  }, [_vm._v("Vyberte obec")]), _c('div', {
    staticClass: "popup__content"
  }, [_c('app-select', {
    attrs: {
      "auto-load": "used_cities"
    },
    model: {
      value: _vm.preselectCity,
      callback: function ($$v) {
        _vm.preselectCity = $$v;
      },
      expression: "preselectCity"
    }
  })], 1), _c('div', {
    staticClass: "popup__title"
  }, [_vm._v("Vyberte zakázku")]), _c('div', {
    staticClass: "popup__content"
  }, [_c('app-select', {
    attrs: {
      "options": _vm.computedProjects
    },
    model: {
      value: _vm.selectedProject,
      callback: function ($$v) {
        _vm.selectedProject = $$v;
      },
      expression: "selectedProject"
    }
  })], 1)] : _vm._e(), _vm.canAddHoursToUser() ? [_c('div', {
    staticClass: "popup__title"
  }, [_vm._v("Uživatel")]), _c('div', {
    staticClass: "popup__content"
  }, [_c('app-select', {
    attrs: {
      "options": _vm.computedUsers,
      "option-key": "id",
      "option-label": "name"
    },
    model: {
      value: _vm.selectedUser,
      callback: function ($$v) {
        _vm.selectedUser = $$v;
      },
      expression: "selectedUser"
    }
  })], 1)] : _vm._e(), _c('div', {
    staticClass: "popup__title"
  }, [_vm._v("Datum")]), _c('div', {
    staticClass: "popup__content"
  }, [_c('app-date', {
    attrs: {
      "disabled-date": _vm.isDateDisabled
    },
    model: {
      value: _vm.selectedDate,
      callback: function ($$v) {
        _vm.selectedDate = $$v;
      },
      expression: "selectedDate"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "popup__title"
  }, [_vm._v("Počet hodin")]), _c('div', {
    staticClass: "popup__content"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.hours,
      expression: "hours"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number",
      "required": ""
    },
    domProps: {
      "value": _vm.hours
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.hours = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "popup__title"
  }, [_vm._v("Počet minut")]), _c('div', {
    staticClass: "popup__content"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.minutes,
      expression: "minutes"
    }],
    staticClass: "form-control",
    attrs: {
      "required": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.minutes = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v("00")]), _c('option', {
    attrs: {
      "value": "0.25"
    }
  }, [_vm._v("15")]), _c('option', {
    attrs: {
      "value": "0.5"
    }
  }, [_vm._v("30")]), _c('option', {
    attrs: {
      "value": "0.75"
    }
  }, [_vm._v("45")])])])])]), _c('div', {
    staticClass: "popup__title"
  }, [_vm._v("Poznámka")]), _c('div', {
    staticClass: "popup__content"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.note,
      expression: "note"
    }],
    staticClass: "form-control",
    attrs: {
      "required": ""
    },
    domProps: {
      "value": _vm.note
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.note = $event.target.value;
      }
    }
  })]), _vm.selectedProject ? [_c('div', {
    staticClass: "popup__title"
  }, [_vm._v("Fotky")]), _c('div', {
    staticClass: "popup__content"
  }, [_vm.visible ? _c('project-files', {
    attrs: {
      "allow-upload": true,
      "handle-upload": false
    },
    on: {
      "newFiles": function (files) {
        return _vm.appendFiles = files;
      }
    }
  }) : _vm._e()], 1)] : _vm._e(), _c('div', {
    staticClass: "popup__buttons"
  }, [_c('app-button', {
    staticClass: "btn btn-primary",
    attrs: {
      "slug": "btn_saveTodaysHours",
      "type": "submit"
    }
  }, [_vm._v("Uložit")]), _c('button', {
    staticClass: "btn btn-secondary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("Zrušit")])], 1)] : _c('div', [_vm._v("Načítání ....")])], 2)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-6"
  }, [_c('h3', [_vm._v("Strávené hodiny")])]);
}]

export { render, staticRenderFns }
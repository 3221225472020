var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.pageLoading ? _c('page-loader') : [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "tabs"
  }, [_c('h2', {
    staticClass: "tab__item",
    class: {
      'active': _vm.tab == 'notapproved'
    },
    on: {
      "click": function ($event) {
        _vm.tab = 'notapproved';
      }
    }
  }, [_vm._v("Neschválené")]), _c('h2', {
    staticClass: "tab__item",
    class: {
      'active': _vm.tab == 'approved'
    },
    on: {
      "click": function ($event) {
        _vm.tab = 'approved';
      }
    }
  }, [_vm._v("Schválené")]), _c('h2', {
    staticClass: "tab__item",
    class: {
      'active': _vm.tab == 'gas'
    },
    on: {
      "click": function ($event) {
        _vm.tab = 'gas';
      }
    }
  }, [_vm._v("Tankování")]), _c('h2', {
    staticClass: "tab__item",
    class: {
      'active': _vm.tab == 'trash'
    },
    on: {
      "click": function ($event) {
        _vm.tab = 'trash';
      }
    }
  }, [_vm._v("Koš")])]), _vm.tab == 'notapproved' || _vm.tab == 'approved' ? _c('div', {
    staticClass: "tabs"
  }, [_c('h3', {
    staticClass: "tab__item",
    class: {
      'active': _vm.filterType == 'shop'
    },
    on: {
      "click": function ($event) {
        _vm.filterType = 'shop';
      }
    }
  }, [_vm._v("Obchod")]), _c('h3', {
    staticClass: "tab__item",
    class: {
      'active': _vm.filterType == 'stock'
    },
    on: {
      "click": function ($event) {
        _vm.filterType = 'stock';
      }
    }
  }, [_vm._v("Sklad")]), _c('h3', {
    staticClass: "tab__item",
    class: {
      'active': _vm.filterType == 'transport'
    },
    on: {
      "click": function ($event) {
        _vm.filterType = 'transport';
      }
    }
  }, [_vm._v("Doprava")])]) : _vm._e()]), _c('div', {
    staticClass: "col-6 flex-buttons"
  })]), _vm.tab == 'notapproved' ? [_c('h3', [_vm._v("Zařazené náklady")]), _c('cost-table', {
    attrs: {
      "items": _vm.naa_items
    }
  }), _c('h3', [_vm._v("Nezařazené náklady")]), _c('cost-table', {
    attrs: {
      "items": _vm.nan_items
    }
  })] : _vm._e(), _vm.tab == 'approved' ? [_c('h3', [_vm._v("Zařazené náklady")]), _c('cost-table', {
    attrs: {
      "items": _vm.aa_items
    }
  }), _c('h3', [_vm._v("Nezařazené náklady")]), _c('cost-table', {
    attrs: {
      "items": _vm.an_items
    }
  })] : _vm._e(), _vm.tab == 'gas' ? [_c('cost-table', {
    attrs: {
      "items": _vm.gas_receipts
    }
  })] : _vm._e(), _vm.tab == 'trash' ? [_c('app-table', _vm._b({
    attrs: {
      "items": _vm.trashed,
      "local-sort": true
    },
    scopedSlots: _vm._u([{
      key: "tbody",
      fn: function (data) {
        return _vm._l(data.rows, function (row, key) {
          var _row$meta$document_nu, _row$meta;

          return _c('tr', {
            key: row.id,
            class: row.color
          }, [_c('td', [_c('cost-link', {
            attrs: {
              "item": row
            }
          })], 1), _c('td', [_vm._v(" " + _vm._s((_row$meta$document_nu = (_row$meta = row.meta) === null || _row$meta === void 0 ? void 0 : _row$meta.document_number) !== null && _row$meta$document_nu !== void 0 ? _row$meta$document_nu : "") + " ")]), _c('td', [_c('user-link', {
            attrs: {
              "item": row.user,
              "position": false
            }
          })], 1), _c('td', [_vm._v(" " + _vm._s(_vm._f("moment")(row.created_at, "DD. MM. YYYY")) + " ")]), _c('td', [_c('app-button', {
            staticClass: "btn btn-danger",
            attrs: {
              "slug": 'btn_forceDeleteCost_' + row.id
            },
            on: {
              "click": function ($event) {
                return _vm.forceDelete(row);
              }
            }
          }, [_vm._v(" Trvalé odstranění ")])], 1)]);
        });
      }
    }], null, false, 912543833)
  }, 'app-table', _vm.tableAtts_trashed, false))] : _vm._e()]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
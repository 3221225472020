var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.handleUpload ? 'form' : 'div', {
    tag: "component",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.uploadNewImages.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "gallery"
  }, [_vm.allowUpload ? _c('div', {
    staticClass: "gallery__itemw no-print"
  }, [_c('div', {
    staticClass: "gallery__item uploader",
    on: {
      "click": _vm.addFile
    }
  }, [_vm._v("+ Přidat")]), _c('input', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "id": _vm.dom_slug,
      "multiple": "",
      "accept": _vm.acceptFormats
    },
    on: {
      "change": _vm.fileSelected
    }
  })]) : _vm._e(), _vm._l(_vm.items, function (item, index) {
    return _c('div', {
      staticClass: "gallery__itemw"
    }, [item.type == 'image' ? [_c('div', {
      staticClass: "gallery__item",
      on: {
        "click": function ($event) {
          return _vm.openImage(index);
        }
      }
    }, [_c('img', {
      attrs: {
        "loading": "lazy",
        "src": item.url
      }
    })])] : item.type == 'video' ? [_c('a', {
      staticClass: "gallery__item",
      attrs: {
        "data-fancybox": "",
        "href": item.url
      }
    }, [_vm._v(_vm._s(item.name || "Video"))])] : item.type == 'audio' ? [_c('a', {
      staticClass: "gallery__item",
      attrs: {
        "data-fancybox": "",
        "href": item.url,
        "data-type": "html5video"
      }
    }, [_vm._v(_vm._s(item.name || "Audio"))])] : item.type == 'pdf' ? [_c('a', {
      staticClass: "gallery__item",
      attrs: {
        "data-fancybox": "",
        "data-type": "pdf",
        "href": item.url
      }
    }, [_vm._v(_vm._s(item.name || "PDF"))])] : [_c('a', {
      staticClass: "gallery__item",
      attrs: {
        "download": "",
        "href": item.url
      }
    }, [_vm._v(_vm._s(item.name || "Soubor"))])], _vm.hasModel && _vm.userCan('detach-image') ? _c('div', {
      staticClass: " no-print"
    }, [_c('a', {
      staticClass: "gallery__delete",
      on: {
        "click": function ($event) {
          return _vm.detachImage(item);
        }
      }
    }, [_vm._v("Smazat")])]) : _vm._e()], 2);
  }), _vm.allowUpload ? _vm._l(_vm.allNewFiles, function (item, index) {
    return _c('div', {
      staticClass: "gallery__itemw"
    }, [_c('div', {
      staticClass: "gallery__item"
    }, [item.type == 'image' ? _c('img', {
      attrs: {
        "loading": "lazy",
        "src": item.url
      }
    }) : _c('span', [_vm._v(_vm._s(item.name || item.type))])]), _c('a', {
      staticClass: "gallery__delete newone no-print",
      on: {
        "click": function ($event) {
          return _vm.deleteFromUpload(item, index);
        }
      }
    }, [_vm._v("Odebrat")])]);
  }) : _vm._e()], 2), _vm.allNewFiles.length && _vm.handleUpload ? _c('div', {
    staticClass: " no-print",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('app-button', {
    staticClass: "btn btn-primary",
    attrs: {
      "slug": "btn_addNewImages"
    }
  }, [_vm._v("Nahrát nové fotky")])], 1)]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }
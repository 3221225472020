<script>
import {gql} from "graphql-tag";

const getItems = gql`
query {
  notApprovedCosts {
    id
    title
    meta {
      shop_name
    }
user {
name
id
}
created_at
  }
}
`

export default {
    name: "DashboardNotApprovedCosts",
    data() {
        return {
            items: []
        }
    },
    methods: {
        fetchData() {
            this.apolloQuery({
                query: getItems
            }).then(res => {
                this.items = res.data.notApprovedCosts;
            })
        }
    },
    mounted() {
        this.fetchData()
    }
}
</script>

<template>
    <div>
        <h3>Neschválené náklady</h3>
        <div style="height: 400px; overflow-y: auto">
            <div class="table-wrapper">
                <table class="table table-striped" v-if="items.length">
                    <tr>
                        <th>Název</th>
                        <th>Založil</th>
                        <th>Vytvořeno</th>
                    </tr>
                    <tr v-for="item in items">
                        <td>
                            <cost-link :item="item"/>
                        </td>
                        <td>{{ item?.user?.name ?? "Anonym" }}</td>
                        <td>{{ item.created_at | moment('DD. MM. YYYY') }}</td>
                    </tr>
                </table>
                <div v-else class="hp__check">
                    <Icon name="check"/>
                </div>
            </div>
        </div>
    </div>
</template>
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.pageLoading ? _c('page-loader') : [_c('div', {
    staticClass: "buttons"
  }, [_vm.userCan('project.create') ? _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'project_edit'
      }
    }
  }, [_vm._v("Nová zakázka")]) : _vm._e(), _c('router-link', {
    staticClass: "btn btn-secondary",
    attrs: {
      "to": {
        name: 'client_index'
      }
    }
  }, [_vm._v("Zákazníci")]), _vm.userCan('nav-cities') ? _c('router-link', {
    staticClass: "btn btn-secondary",
    attrs: {
      "to": {
        name: 'city_index'
      }
    }
  }, [_vm._v("Obce")]) : _vm._e(), _vm.hasRole([1]) ? _c('router-link', {
    staticClass: "btn btn-secondary",
    attrs: {
      "to": {
        name: 'shop_index'
      }
    }
  }, [_vm._v("Obchody")]) : _vm._e(), _vm.hasRole([1]) ? _c('router-link', {
    staticClass: "btn btn-secondary",
    attrs: {
      "to": {
        name: 'project_merge'
      }
    }
  }, [_vm._v("Spojování zakázek")]) : _vm._e(), _vm.hasRole([1]) ? _c('router-link', {
    staticClass: "btn btn-secondary",
    attrs: {
      "to": {
        name: 'project_internal'
      }
    }
  }, [_vm._v("Interní zakázky")]) : _vm._e()], 1), _c('br'), _c('br'), _c('h4', [_vm._v("Filtrovat dle kategorie")]), _c('div', {
    staticClass: "flex-items"
  }, [_c('button', {
    staticClass: "btn btn-secondary",
    class: {
      'btn-primary': _vm.filterProjectCategory == null
    },
    on: {
      "click": function ($event) {
        _vm.filterProjectCategory = null;
      }
    }
  }, [_vm._v("Vše")]), _vm._l(_vm.filterableCategories, function (item) {
    return _c('button', {
      staticClass: "btn btn-secondary",
      class: {
        'btn-primary': _vm.filterProjectCategory == item.id
      },
      on: {
        "click": function ($event) {
          _vm.filterProjectCategory = item.id;
        }
      }
    }, [_vm._v(_vm._s(item.label))]);
  })], 2), _vm.notVerifiedItems.length ? [_c('h3', [_vm._v("Neverifikované zakázky")]), _c('app-table', _vm._b({
    attrs: {
      "items": _vm.notVerifiedItems,
      "local-sort": true
    },
    scopedSlots: _vm._u([{
      key: "tbody",
      fn: function (data) {
        return _vm._l(data.rows, function (row, key) {
          var _row$city;

          return _c('tr', {
            key: row.id,
            class: row.color
          }, [_c('td', [_vm._v(" " + _vm._s((_row$city = row.city) === null || _row$city === void 0 ? void 0 : _row$city.name) + " ")]), _c('td', [_c('project-link', {
            attrs: {
              "item": row,
              "hide-client": true
            }
          })], 1), _c('td', [_c('client-link', {
            attrs: {
              "item": row === null || row === void 0 ? void 0 : row.client
            }
          })], 1), _c('td', [_vm._v(" " + _vm._s(_vm._f("moment")(row.created_at, "DD. MM. YYYY")) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm._f("moment")(row.updated_at, "DD. MM. YYYY HH:mm")) + " ")]), _c('td', [_vm._v(" " + _vm._s(row.category_name) + " ")])]);
        });
      }
    }], null, false, 1886556537)
  }, 'app-table', _vm.table_attributes_notVerified, false))] : _vm._e(), _vm.verifiedItems.length ? [_c('h3', [_vm._v("Rozpracované zakázky")]), _c('app-table', _vm._b({
    attrs: {
      "items": _vm.verifiedItems,
      "local-sort": true
    },
    scopedSlots: _vm._u([{
      key: "tbody",
      fn: function (data) {
        return _vm._l(data.rows, function (row, key) {
          var _row$city2;

          return _c('tr', {
            key: row.id,
            class: row.color
          }, [_c('td', [_vm._v(" " + _vm._s((_row$city2 = row.city) === null || _row$city2 === void 0 ? void 0 : _row$city2.name) + " ")]), _c('td', [_c('project-link', {
            attrs: {
              "item": row,
              "hide-client": true
            }
          })], 1), _c('td', [_c('client-link', {
            attrs: {
              "item": row === null || row === void 0 ? void 0 : row.client
            }
          })], 1), _c('td', [_vm._v(" " + _vm._s(_vm._f("moment")(row.created_at, "DD. MM. YYYY")) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm._f("moment")(row.updated_at, "DD. MM. YYYY HH:mm")) + " ")]), _c('td', [_vm._v(" " + _vm._s(row.category_name) + " ")])]);
        });
      }
    }], null, false, 1886556537)
  }, 'app-table', _vm.table_attributes_wip, false))] : _vm._e(), _vm.newProjects.length && _vm.userCan('project.see-planned') ? [_c('h3', [_vm._v("Plánované zakázky")]), _c('app-table', _vm._b({
    attrs: {
      "items": _vm.newProjects,
      "local-sort": true
    },
    scopedSlots: _vm._u([{
      key: "tbody",
      fn: function (data) {
        return _vm._l(data.rows, function (row, key) {
          var _row$city3;

          return _c('tr', {
            key: row.id,
            class: row.color
          }, [_c('td', [_vm._v(" " + _vm._s((_row$city3 = row.city) === null || _row$city3 === void 0 ? void 0 : _row$city3.name) + " ")]), _c('td', [_c('project-link', {
            attrs: {
              "item": row,
              "hide-client": true
            }
          })], 1), _c('td', [_c('client-link', {
            attrs: {
              "item": row === null || row === void 0 ? void 0 : row.client
            }
          })], 1), _c('td', [_vm._v(" " + _vm._s(_vm._f("moment")(row.created_at, "DD. MM. YYYY")) + " ")]), _c('td', [_vm._v(" " + _vm._s(row.category_name) + " ")])]);
        });
      }
    }], null, false, 3497527251)
  }, 'app-table', _vm.table_attributes_planned, false))] : _vm._e(), _vm.computedFinished.length && _vm.userCan('project.see-finished') ? [_c('h3', [_vm._v("Hotové zakázky")]), _c('app-table', _vm._b({
    attrs: {
      "items": _vm.computedFinished,
      "local-sort": true
    },
    scopedSlots: _vm._u([{
      key: "tbody",
      fn: function (data) {
        return _vm._l(data.rows, function (row, key) {
          var _row$city4;

          return _c('tr', {
            key: row.id,
            class: row.color
          }, [_c('td', [_vm._v(" " + _vm._s((_row$city4 = row.city) === null || _row$city4 === void 0 ? void 0 : _row$city4.name) + " ")]), _c('td', [_c('project-link', {
            attrs: {
              "item": row,
              "hide-client": true
            }
          })], 1), _c('td', [_c('client-link', {
            attrs: {
              "item": row === null || row === void 0 ? void 0 : row.client
            }
          })], 1), _c('td', [_vm._v(" " + _vm._s(_vm._f("moment")(row.finished_at, "DD. MM. YYYY")) + " ")]), _c('td', [_vm._v(" " + _vm._s(row.category_name) + " ")]), _c('td', [row.finished_at ? _c('span', [row.is_invoiced == 1 ? _c('span', {
            staticClass: "text-success bold"
          }, [_vm._v("Ano")]) : row.is_invoiced == 2 ? _c('span', {
            staticClass: "text-info bold"
          }, [_vm._v("Neřeší se")]) : row.is_invoiced == 3 ? _c('span', {
            staticClass: "text-info bold"
          }, [_vm._v("Částečně")]) : _c('span', {
            staticClass: "text-danger bold"
          }, [_vm._v("Ne")])]) : _vm._e()]), _c('td', [_vm._v(" " + _vm._s(row.invoice_numbers) + " ")])]);
        });
      }
    }], null, false, 2690327215)
  }, 'app-table', _vm.table_attributes_finished, false))] : _vm._e()]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.pageLoading ? _c('page-loader') : [_vm.wipProjects.length ? [_c('h3', [_vm._v("Rozpracované zakázky")]), _c('app-table', _vm._b({
    attrs: {
      "items": _vm.wipProjects,
      "local-sort": true
    },
    scopedSlots: _vm._u([{
      key: "tbody",
      fn: function (data) {
        return _vm._l(data.rows, function (row, key) {
          var _row$city;

          return _c('tr', {
            key: row.id,
            class: row.color
          }, [_c('td', [_vm._v(" " + _vm._s((_row$city = row.city) === null || _row$city === void 0 ? void 0 : _row$city.name) + " ")]), _c('td', [_c('project-link', {
            attrs: {
              "item": row,
              "hide-client": true
            }
          })], 1), _c('td', [_c('client-link', {
            attrs: {
              "item": row === null || row === void 0 ? void 0 : row.client
            }
          })], 1), _c('td', [_vm._v(" " + _vm._s(_vm._f("moment")(row.created_at, "DD. MM. YYYY")) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm._f("moment")(row.updated_at, "DD. MM. YYYY HH:mm")) + " ")]), _c('td', [_vm._v(" " + _vm._s(row.category_name) + " ")])]);
        });
      }
    }], null, false, 1886556537)
  }, 'app-table', _vm.table_attributes_wip, false))] : _vm._e(), _vm.computedFinished.length && _vm.userCan('project.see-finished') ? [_c('h3', [_vm._v("Hotové zakázky")]), _c('app-table', _vm._b({
    attrs: {
      "items": _vm.computedFinished,
      "local-sort": true
    },
    scopedSlots: _vm._u([{
      key: "tbody",
      fn: function (data) {
        return _vm._l(data.rows, function (row, key) {
          var _row$city2;

          return _c('tr', {
            key: row.id,
            class: row.color
          }, [_c('td', [_vm._v(" " + _vm._s((_row$city2 = row.city) === null || _row$city2 === void 0 ? void 0 : _row$city2.name) + " ")]), _c('td', [_c('project-link', {
            attrs: {
              "item": row,
              "hide-client": true
            }
          })], 1), _c('td', [_c('client-link', {
            attrs: {
              "item": row === null || row === void 0 ? void 0 : row.client
            }
          })], 1), _c('td', [_vm._v(" " + _vm._s(_vm._f("moment")(row.finished_at, "DD. MM. YYYY")) + " ")]), _c('td', [_vm._v(" " + _vm._s(row.category_name) + " ")]), _c('td', [row.finished_at ? _c('span', [row.is_invoiced == 1 ? _c('span', {
            staticClass: "text-success bold"
          }, [_vm._v("Ano")]) : row.is_invoiced == 2 ? _c('span', {
            staticClass: "text-info bold"
          }, [_vm._v("Neřeší se")]) : row.is_invoiced == 3 ? _c('span', {
            staticClass: "text-info bold"
          }, [_vm._v("Částečně")]) : _c('span', {
            staticClass: "text-danger bold"
          }, [_vm._v("Ne")])]) : _vm._e()]), _c('td', [_vm._v(" " + _vm._s(row.invoice_numbers) + " ")])]);
        });
      }
    }], null, false, 2690327215)
  }, 'app-table', _vm.table_attributes_finished, false))] : _vm._e()]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }